import React from "react";
import { graphql } from "gatsby";
import Layout from "@common/Layout";
import { TopHeader } from "@common/TopHeader";
import Footer from "@sections/Footer";
import styled from "styled-components";
import { Section, Container } from "@components/global";

const StandaloneText = styled.div`
  max-width: 991px;
  margin: 0 auto;
  padding: 0 1rem;
  & p {
    margin-bottom: 1rem;
    & strong {
      color: ${(props) => props.theme.color.black.regular};
    }
  }
  margin-bottom: 2rem;
`;

const HeaderH1 = styled.h1`
  margin-top: 0.5rem;
  text-align: center;
`;

const PostImg = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: center;
  @media (min-width: 600px) {
    height: 500px;
  }
  margin-bottom: 2rem;
`;

const Post = ({ data: { prismicPost } }) => {
  const { data } = prismicPost;
  return (
    <Layout>
      <TopHeader />

      <Section fluid>
        <HeaderH1 style={{ marginBottom: "1.5rem" }}>
          {data.title.text}
        </HeaderH1>
        <StandaloneText>
          <PostImg src={data.image.url} />
          <div dangerouslySetInnerHTML={{ __html: data.content.html }} />
        </StandaloneText>
      </Section>
      <Footer />
    </Layout>
  );
};
export default Post;
export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        image {
          url
        }
        content {
          html
        }
      }
    }
  }
`;
